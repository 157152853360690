import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"
import Treatments from "../components/Treatments"

const getTreatments = graphql`
  query {
    allContentfulTreatmentsListing {
      nodes {
        listiing {
          id
          slug
          title
          introduction {
            introduction
          }
        }
      }
    }
    fileName: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(getTreatments)

  console.log("data", data)

  return (
    <Layout>
      <Banner
        title="Our Treatments"
        ishome={false}
        image={data.fileName.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-treatments">
            <div className="container">
              <div className="row">
                {data.allContentfulTreatmentsListing.nodes[0].listiing.map(
                  (item, index) => {
                    return <Treatments key={index} item={item} />
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Testimonials />
    </Layout>
  )
}
